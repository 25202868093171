




import Vue from 'vue';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '@/awsconfig';
import awsauth from '@/awsauth';

Amplify.configure(awsconfig);
Auth.configure({ oauth: awsauth });

export default Vue.extend({
  mounted() {
    Auth.signOut({ global: true }).then(() => this.$router.push('/'));
  },
});
